import { Link } from "gatsby"
import React from "react"
import about5 from "../../images/about/Group 60.png"
import about6 from "../../images/about/Group 61.png"
import leadership from "../../images/Group 141.png"
import StandardLayout from "../../layouts/standard"

export default function Infrastructure(props) {
  return (
    <StandardLayout title="Infrastructure">
      {/* <AboutUsPageComponent
        name="Infrastructure"
        description={` Stylam Industries Limited owns a well-furnished,Asia's largest
            installation unit and has a production capacity around 1.3 million
            sheets of laminates per month.`}
      /> */}

      <section className="">
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div class="video-container">
            <div className="immense-container">
              <div>
                Immense
                <br />
                Presence
              </div>
              <div>
                Stylam Industries Limited owns a well-
                <br />
                furnished, Asia's largest installation unit.
              </div>
            </div>
            <div className="video-cont">
              <video
                width="100%"
                height="100%"
                controls
                poster="https://stylam-upload.s3.amazonaws.com/Group_200_e5c008b39b.png"
                // data-uk-video="autoplay: inview"
              >
                <source
                  src="https://videos-new-website.s3.ap-south-1.amazonaws.com/stylam.mov"
                  type="video/mp4"
                ></source>
              </video>
            </div>
          </div>

          <div className="infra-text-cont">
            <div>Infrastructure</div>
            <div>
              Being a pioneer of the laminate industry, our innovative product
              range and adoption of the latest manufacturing processes has made
              us a global brand. Over 130,000 Sqm in our Manak Tabra and
              Panchkula plant facilities, there we have a section-wise division
              based on the different departments of our organization which helps
              in enhancing the work rate and productivity. The fully automated
              machines from reputed suppliers in Europe (Italy | Germany | UK |
              Spain) and India has been installed in our units for production of
              optimum quality products. Our extensive stockpiling unit is
              developed with innovation at its heart to keep up well-suited
              capacity conditions and hold the nature of the item. The quality
              unit ensures all the quality guidelines parameters are considered
              and followed. Currently we are designing a variety of world class
              laminates for residential and commercial use.
            </div>
          </div>
        </div>

        <div className="our-units">OUR UNITS</div>

        <div className="plants-cont">
          <div className="plant">
            <div className="plant-num">Plant 1</div>
            <div className="plant-name">manaktabra</div>
            <div className="plant-detail">
              <span>Established: 2017</span>
              <br />
              <span>Location: Manaktabra, Raipur Rani, Panchkula, Haryana</span>
              <br />
              <span>Area: 165000 square metres</span>
              <br />
            </div>
          </div>
          <div className="plant">
            <div className="plant-num">Plant 1</div>
            <div className="plant-name">manaktabra</div>
            <div className="plant-detail">
              <span>Established: 2017</span>
              <br />
              <span>Location: Manaktabra, Raipur Rani, Panchkula, Haryana</span>
              <br />
              <span>Area: 165000 square metres</span>
              <br />
            </div>
          </div>
        </div>

        <div className="quality-cont">
          <div>Quality</div>
          <div>
            Stylam range of products undergoes some of the most stringent
            quality check within the industry, before the product becomes a part
            of your residential or commercial space. Check parameters are based
            on heat resistance, scratch-resistance and Stain-Resistance. Stylam
            has ISI certified laboratory with specialised QA machines from
            Europe (Italy | Germany | UK | Spain) and India. We have well
            qualified and dedicated Research and Development team that works
            constant towards innovation and improvement, resulting in formation
            of superior quality products. Moreover, we have Experts from around
            the world to reinforce our knowledge base.
          </div>
        </div>

        <div className="leadership-cont">
          <img
            src={leadership}
            style={{ height: "605px", width: "100%", objectFit: "cover" }}
            alt="leadership context"
          />
          <div className="leadership-text">
            <div className="leadership-text1">OUR LEADERSHIP</div>
            <div className="leadership-text2">TEAM</div>
            <div className="leadership-text3">
              Stylam is a global entity with a highly skilled workforce of
              experienced engineers, technicians and management, who are well
              versed with every aspect of their field. Our team of professionals
              work hard to provide their clients with the best value for their
              investment of time and money.
            </div>
            <button
              className="new-maroon-btn uk-margin-small-top"
              uk-toggle="target: #our-leadership-modal"
            >
              Know Us
            </button>
          </div>
        </div>
        <div className="morelink-cont">
          <div className="morelink-img">
            <div>
              <strong>
                <Link to="/about/who-are-we">About Us</Link>
              </strong>
            </div>

            <img src={about5} alt="" />
          </div>
          <div className="morelink-img">
            <div>
              <strong>
                <Link to="/about/our-core-values">Our Core Values</Link>
              </strong>
            </div>

            <img src={about6} alt="" />
          </div>
        </div>
      </section>

      <div id="our-leadership-modal" data-uk-modal>
        <div class="uk-modal-dialog">
          <button class="uk-modal-close-default m-close" type="button" uk-close>
            &#10006;
          </button>
          <div>
            <img src={require("../../images/health/team-img.jpg").default} />

            <div className="team-names">
              <table>
                <tbody>
                  <tr>
                    <td>
                      <h3>Jagdish Gupta</h3>
                      <p>MD</p>
                    </td>

                    <td>
                      <h3>Manav Gupta</h3>
                      <p>Director</p>
                    </td>

                    <td>
                      <h3>Sachin Bhatla</h3>
                      <p>Director - Technical</p>
                    </td>

                    <td>
                      <h3>JagatBir Sing Kang</h3>
                      <p>VP International Sales & marketing (Exports)</p>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <h3>Manit Gupta</h3>
                      <p>Director</p>
                    </td>

                    <td>
                      <h3>Vijay Bhatia</h3>
                      <p>President - Commercial</p>
                    </td>

                    <td>
                      <h3>Rajeev Gupta</h3>
                      <p>President - Sales & Merketing (India)</p>
                    </td>

                    <td>
                      <h3>Kishan Nagpal</h3>
                      <p>CFO</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </StandardLayout>
  )
}
